//运动打卡列表【分页】
const getSportListUrl = `/gateway/hc-health/manageapi/healthRecord/listPaging`;
//运动打卡审核
const verifyUrl = `/gateway/hc-health/manageapi/healthRecord/audit`;
// 运动打卡批量审核
const batchReviewUrl = `/gateway/hc-health/manageapi/healthRecord/batch-review`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;

export { getSportListUrl, verifyUrl, getCommunityListUrl, batchReviewUrl };

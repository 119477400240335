<template>
  <div class="recordSport">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isManySelect="true"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->
        <v-select
          clearable
          :options="auditStatusList"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #batchSlot="scope">
        <v-button
          v-if="searchParam.auditStatus == 0"
          text="批量审核"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="toDetail(scope.row)" />
      </template>
    </v-list>
    <!-- 批量审核弹框 -->
    <el-dialog
      title="批量审核"
      :visible.sync="batchAuditDiglog"
      :close-on-click-modal="false"
    >
      <div class="recordSportDialog">
        <el-form label-width="100px">
          <el-form-item label="审核结果">
            <v-select :options="handleStatus" v-model="batchAuditAuditStatus" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialogBottomBtn">
          <v-button
            size="medium"
            @click="batchAuditDiglog = false"
            text="关闭"
          ></v-button>
          <v-button
            size="medium"
            type="primary"
            @click="batchAudit"
            text="确定"
          ></v-button>
        </div>
      </div>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog
      title="详情"
      :visible.sync="detailDialogFormVisible"
      :close-on-click-modal="false"
    >
      <div class="recordSportDialog">
        <el-form label-width="100px">
          <el-form-item label="用户名">
            <v-input v-model="tempObj.userName" disabled />
          </el-form-item>
          <el-form-item label="审核结果">
            <v-select
              :options="handleStatus"
              :disabled="tempObj.auditStatusOrg != 0"
              v-model="tempObj.auditStatus"
            />
          </el-form-item>
          <el-form-item label="备注信息">
            <v-input
              v-model="tempObj.auditRemarks"
              type="textarea"
              :maxlength="100"
              :disabled="tempObj.auditStatusOrg != 0"
            />
          </el-form-item>
          <el-form-item label="详情">
            <div class="detailImg" v-if="detailImg.length > 0">
              <div class="item" v-for="(item, index) in detailImg" :key="index">
                <img :src="item" alt="" @click="large(item)" />
              </div>
            </div>
            <div class="detailImg" v-else>无内容</div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialogBottomBtn">
          <v-button
            size="medium"
            @click="detailDialogFormVisible = false"
            text="关闭"
          ></v-button>
          <v-button
            v-if="tempObj.auditStatusOrg == 0"
            size="medium"
            type="primary"
            @click="doAudit"
            text="确定"
          ></v-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createImgVNode, createHTMLVNode } from "@/utils/utils.js";
import { auditStatusOptions, auditStatusMap, auditStatusOps } from "./map";
import { verifyUrl, getSportListUrl, batchReviewUrl } from "./api";
export default {
  name: "recordSport",
  data() {
    return {
      detailImg: [],
      batchAuditDiglog: false,
      detailDialogFormVisible: false,
      auditStatusList: auditStatusOptions,
      batchAuditAuditStatus: null,
      searchParam: {
        spaceIds: null,
        auditStatus: 0,
      },
      tableUrl: getSportListUrl,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "healthPic",
          label: "图片",
          formatter: (row, prop) => {
            let arr;
            let oHtml = "";
            if (row.healthPic) {
              arr = row.healthPic.split(",");
            }
            for (let i = 0; i <= arr.length - 1; i++) {
              oHtml += `<img src="${arr[i]}"/>`;
            }
            return createHTMLVNode(this, oHtml, row, prop, {
              cb: (e, row) => {
                let url = "";
                if (e.target && e.target.getAttribute("src")) {
                  url = e.target.getAttribute("src");
                }
                this.$previewBox([url]);
              },
            });
          },
        },
        {
          prop: "roomName",
          label: "用户房号",
        },
        {
          prop: "insertTime",
          label: "提交时间",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          align: "center",
          formatter: (row, prop) => {
            for (let i = 0; i < auditStatusOptions.length; i++) {
              if (auditStatusOptions[i].value == row.auditStatus) {
                return auditStatusOptions[i].label;
              }
            }
          },
        },
        {
          prop: "auditRemarks",
          label: "审核备注",
        },
      ],

      auditDialogFormVisible: false,
      tempObj: {
        id: null,
        userName: null,
        userId: null,
        auditStatus: 1,
        auditStatusOrg: null,
        auditRemarks: null,
        bonusPoints: null,
      },
      batchSendIdList: [],
    };
  },
  components: {},
  mounted() {},
  computed: {
    handleStatus() {
      let arr = JSON.parse(JSON.stringify(auditStatusOptions));
      arr.splice(0, 1);
      return arr;
    },
  },
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    batchSend(list) {
      this.batchAuditDiglog = true;
      const { data, ids } = list;
      this.batchSendIdList = ids;
      console.log(this.batchSendIdList);
    },
    batchAudit() {
      if (this.batchAuditAuditStatus == null) {
        this.$message.error("请选择审核结果！");
        return;
      }
      let params = {
        ids: this.batchSendIdList,
        adminId: this.$store.state.app.userInfo.userId,
        auditStatus: this.tempObj.auditStatus,
        auditRemarks: this.tempObj.auditRemarks,
        tenantId: this.$store.state.app.userInfo.tenantId,
        bonusPoints: this.tempObj.bonusPoints,
      };
      this.$axios.post(`${batchReviewUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.batchAuditDiglog = false;
          this.batchAuditAuditStatus = null;
          this.$message.success("操作成功");
          this.$refs.list.search();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    large(item) {
      this.$previewBox([item]);
    },
    toDetail(row) {
      this.detailImg = [];
      if (row.healthPic && row.healthPic.split(",")) {
        this.detailImg = row.healthPic.split(",");
      }

      this.tempObj = {
        id: row.id,
        userName: row.userName,
        auditRemarks: row.auditRemarks,
        auditStatus: row.auditStatus,
        auditStatusOrg: row.auditStatus,
      };
      console.log(this.tempObj);
      if (row.auditStatus == 0) {
        this.tempObj.auditStatus = "";
      }
      this.detailDialogFormVisible = true;
    },
    doAudit() {
      // if (this.tempObj.auditRemarks == "") {
      //   this.$message.error("请选择审核结果！");
      //   return;
      // }
      let params = {
        id: this.tempObj.id,
        adminId: this.$store.state.app.userInfo.userId,
        auditStatus: this.tempObj.auditStatus,
        auditRemarks: this.tempObj.auditRemarks,
        tenantId: this.$store.state.app.userInfo.tenantId,
        bonusPoints: this.tempObj.bonusPoints,
      };
      this.$axios.post(`${verifyUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.detailDialogFormVisible = false;
          this.$message.success("操作成功");

          this.$refs.list.search();
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.recordSport {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-table__row {
    .vnode-wrapper {
      text-align: center;
    }
    img {
      width: 48px !important;
      height: 48px !important;
    }
  }
  .detailImg {
    max-width: 400px;
    max-height: 400px;
    display: flex;
    overflow: auto;
    img {
      width: 100%;
    }
  }
}
</style>
<style lang="less">
.recordSportDialog {
  textarea {
    height: 100px;
  }
}
</style>
